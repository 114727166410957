import { GetDay } from "./Stats";
import { GameState } from "./Game";
import { todayDate } from "./util";

interface StatusViewProps {
  status: string | null;
}

export function StatusView({ status }: StatusViewProps) {
  const day = GetDay(todayDate);
  const gameState = day ? day.gameState : null;
  const guesses = day ? (gameState === GameState.Lost ? "X" : day.guesses.length) : "-";

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '8px 12px',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    backgroundColor: '#1a1a1a',
    color: '#ffffff',
    borderRadius: '8px',
    gap: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    maxWidth: 'fit-content'
  };

  const topRowStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };

  const bottomRowStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    paddingTop: '4px'
  };

  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '16px',
    fontWeight: 500,
    color: '#ffffff',
    textDecoration: 'none'
  };

  const authorStyle = {
    fontSize: '14px',
    color: '#999',
    textDecoration: 'none'
  };

  const statusStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '16px',
    fontWeight: 500,
    padding: '4px 8px',
    borderRadius: '6px',
    backgroundColor: gameState === GameState.Won ? '#1a472a' : 
                    gameState === GameState.Lost ? '#5c1a1a' :
                    gameState === GameState.Playing ? '#2a3b4d' : 'transparent'
  };

  const rulesStyle = {
    fontSize: '14px',
    color: '#999'
  };

  return (
    <div style={containerStyle}>
      <div style={topRowStyle}>
        <img 
          width="24" 
          height="24" 
          src="favicon.png" 
          alt="Shuntle"
          style={{ borderRadius: '4px' }}
        />
        
        <a href="https://shuntle.com" style={titleStyle}>Shuntle</a>
        <span style={{ color: '#666' }}>by</span>
        <div style={authorStyle}>LSK, coded by keldor</div>
      </div>

      {(
        <div style={bottomRowStyle}>
          {gameState !== null && (
            <>
              {gameState === GameState.Won && (
                <div style={statusStyle}>✅ {guesses}/6</div>
              )}
              {gameState === GameState.Lost && (
                <div style={statusStyle}>💀 X/6</div>
              )}
              {gameState === GameState.Playing && (
                <div style={statusStyle}>🎲 {guesses}/6</div>
              )}
            </>
          )}
          
          {status === "full" && (
            <div style={rulesStyle}>
              The secret word is 4-7 letters long, with spaces on the left or right.
            </div>
          )}
        </div>
      )}
    </div>
  );
} 